.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f4ede5;
    font-family: 'DM Serif Text', serif;
    color: #343d4eff;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.coming-soon img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

@media screen and (min-width: 915px) {
    .coming-soon img {
        height: 80%;
        width: auto;
        margin-bottom: 4rem;
        margin-inline: auto;
    }
    
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f4ede5;
    font-family: 'DM Serif Text', serif;
    color: #343d4eff;
}

.loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 2rem;
}

.loading-text svg {
    width: 2rem;
    height: 2rem;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.heart-icon {
    position: absolute;
    width: 3rem;
    height: 3rem;
    margin-bottom: 50px;
}

.heart-icon-2 {
    position: absolute;
    width: 3rem;
    height: 3rem;
    margin-bottom: 50px;
}

.heart-fill {
    position: absolute;
    fill: #594c4c;
    width: 3rem;
    height: 3rem;
    margin-bottom: 50px;
    background-color: #f4ede5;
    animation: fill-up 0.5s infinite alternate;
}

@keyframes fill-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}
