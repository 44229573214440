/****************************************
  Base (Desktop-First) Styles
*****************************************/
.navbar {
    width: 100%;
    height: 90px;
    background-color: #343d4eff;
    display: flex;
    flex-direction: row;
    /* Desktop: side-by-side */
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
}

.navbar-logo {
    color: white;
    font-size: 2rem;
    margin-left: 20px;
}

.navbar-logo a {
    color: white;
    text-decoration: none;
    font-family: 'MyFont';
}

/* Desktop Navigation Links */
.desktop-nav {
    display: flex;
    /* Visible on desktop */
    list-style: none;
    margin-right: 20px;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-left: 35px;
}
.navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    list-style: none;
}
/****************************************
    Hamburger / Mobile Nav (hidden by default)
  *****************************************/
.mobile-nav {
    display: none;
    /* Hidden on desktop screens */
    margin-right: 20px;
    z-index: 10000;
}

/* The Trigger in Radix is effectively a button/link */
.hamburger-trigger {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

/* Hamburger Icon (3 stacked bars) */
.hamburger-icon {
    width: 30px;
    height: 3px;
    background-color: white;
    position: relative;
    display: inline-block;
}

.hamburger-icon::before,
.hamburger-icon::after {
    content: "";
    width: 30px;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
}

.hamburger-icon::before {
    top: -8px;
}

.hamburger-icon::after {
    top: 8px;
}

/* The mobile menu content from Radix 
     By default, you can hide it and show on [data-state="open"] 
  */
.mobile-menu {
    display: none;
    /* Hidden until opened by Radix */
    flex-direction: column;
    background-color: #343d4eff;
    margin-top: 8px;
    padding: 10px;
    list-style: none;
    overflow: hidden;
    position: relative;
}

/* Radix UI sets [data-state="open"] on the NavigationMenu.Item 
     when it's expanded. So we can show the content. 
  */
.mobile-menu[data-state="open"] {
    display: flex;
    position: absolute;
    right: calc(50% - 30px - 15px);
    /* right: 0; */
}

.mobile-menu .navbar-link {
    margin-left: 0;
    /* Remove default left margin for stacked links */
    padding: 10px 0;
    font-size: 1rem;
    /* Adjust text size for mobile if desired */
}

/****************************************
    Media Queries (Mobile)
  *****************************************/
@media (max-width: 974px) {

    /* Hide desktop nav, show mobile nav */
    .desktop-nav {
        display: none;
    }

    .mobile-nav {
        display: block;
        position: relative;
    }

    .navbar {
        flex-direction: row;
        /* Keep logo + hamburger side by side if you like */
        align-items: center;
        padding: 10px;
        height: 70px;
    }

    .navbar-logo {
        margin-left: 10px;
        font-size: 1.5rem;
        /* Slightly smaller logo on mobile */
    }
}
